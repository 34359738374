import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import {
  MarketingCampaignDetailDto,
  MarketingCampaignProcessListItemDto,
  MarketingCampaignService
} from '@konnektu/marketing-campaigns/data';
import { format } from 'date-fns';

export const MARKETING_CAMPAIGN_ROUTES: Routes = [
  {
    path: 'marketing-campaigns',
    providers: [MarketingCampaignService],
    data: {
      breadcrumb: 'marketingCampaign.breadcrumb.sectionBreadcrumb'
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@konnektu/marketing-campaigns/section').then(
            (c) => c.MarketingCampaignSectionComponent
          ),
        data: {
          breadcrumb: null
        }
      },
      {
        path: ':id',
        data: {
          breadcrumb: (data: {
            marketingCampaign: MarketingCampaignDetailDto;
          }) => data.marketingCampaign.name
        },
        resolve: {
          marketingCampaign: (snapshot: ActivatedRouteSnapshot) =>
            inject(MarketingCampaignService).getMarketingCampaign(
              Number(snapshot.paramMap.get('id'))
            )
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null
            },
            loadComponent: () =>
              import('@konnektu/marketing-campaigns/bpmn-constructor').then(
                (m) => m.CampaignConstructorComponent
              )
          },
          {
            path: 'processes/:id',
            loadComponent: () =>
              import('@konnektu/marketing-campaigns/bpmn-constructor').then(
                (m) => m.ProcessViewerComponent
              ),
            data: {
              breadcrumb: (data: {
                marketingCampaign: MarketingCampaignDetailDto;
                marketingCampaignProcess: MarketingCampaignProcessListItemDto;
              }) =>
                format(
                  new Date(data.marketingCampaignProcess.startedAt),
                  'dd.MM.yyyy HH:mm'
                )
            },
            resolve: {
              marketingCampaignProcess: (snapshot: ActivatedRouteSnapshot) =>
                inject(MarketingCampaignService).getMarketingCampaignProcess(
                  Number(snapshot.paramMap.get('id'))
                )
            }
          }
        ]
      }
    ]
  }
];
